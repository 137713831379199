// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './reducers/auth'
import layout from './reducers/layout'
import checkInSheet from './reducers/checkInSheet'
import propsOfPatient from './reducers/propsOfPatient'

const rootReducer = combineReducers({
  auth,
  layout,
  checkInSheet,
  propsOfPatient
})

export default rootReducer
