// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClientProvider } from '@tanstack/react-query'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/store'

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability'
import { ToastContainer } from 'react-toastify'
import { AbilityContext } from './utility/context/Can'
import { ThemeContext } from './utility/context/ThemeColors'
import { IntlProviderWrapper } from './utility/context/Internationalization'
import { SocketContext } from '@utility/context/SocketContext'
import { S3ImageProvider } from '@utility/context/ImageContext'

import queryClient from '@utility/queryClient'

// ** Yup intl message
import './configs/yupConfig'

// ** Firebase Config
import './configs/firebaseConfig'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@core/scss/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Service Worker
// import * as serviceWorker from './serviceWorker'
import ServiceWorkerWrapper from './ServiceWorkerWrapper'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <S3ImageProvider>
              <SocketContext>
                <IntlProviderWrapper>
                  <LazyApp />
                  <ToastContainer newestOnTop />
                  <ServiceWorkerWrapper />
                </IntlProviderWrapper>
              </SocketContext>
            </S3ImageProvider>
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </Provider>
  </QueryClientProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()
