import { QueryClient } from '@tanstack/react-query'

// default options for cached queries
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 4,    // 4 hours
      cacheTime: 1000 * 60 * 60 * 5     // 5 hours
    }
  }
})

export default queryClient
