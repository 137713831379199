import { ACTION_TYPES } from '@store/actionTypes'

// **  Initial State
const initialState = {
  appointmentId: 0,
  checkInSheetId: 0,
  orderNumber: 0,
  formId: '',
  formName: '',
  printTemplateFileUrl: '',
  properties: []
}

const checkInSheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SAVE_CHECK_IN_SHEET:
      if (action.data) {
        return {
          ...state,
          ...action.data
        }
      } else {
        return { ...state }
      }

    default:
      return state
  }
}

export default checkInSheetReducer
