import { createContext, useEffect, useState, useCallback } from 'react'
import { getFileUrlsAPI } from '@api/main'
import Toast from '@utility/toast'

export const S3ImageContext = createContext()

export const S3ImageProvider = ({ children }) => {
  const [ids, setIds] = useState([])
  const [url, setUrl] = useState([])

  const s3ImageHandle = useCallback(
    (id, callback) => {
      if (id <= 0) return
      if (ids.includes(id)) {
        callback(url.find(i => i.physicalFileId === id)?.fileUrl ?? '')
      } else {
        setIds(prev => [...prev, id])
      }
    },
    [ids, url]
  )

  useEffect(() => {
    let currentIndex = 0
    let interval = null

    const fetchData = async () => {
      const uniqueIds = [...new Set(ids)]
      const sliceIds = uniqueIds.slice(currentIndex, currentIndex + 10)
      currentIndex += 10
      // end interval
      if (sliceIds.length === 0) {
        clearInterval(interval)
        return
      }
      try {
        const res = await getFileUrlsAPI({
          physicalFileIds: sliceIds
        })
        setUrl(prevUrl => [...prevUrl, ...res.data])
      } catch (error) {
        Toast.showError('toast.error', getErrorMessage(error))
      }
    }
    // start interval
    interval = setInterval(fetchData, 400)
    return () => clearInterval(interval)
  }, [ids])

  return <S3ImageContext.Provider value={s3ImageHandle}>{children}</S3ImageContext.Provider>
}
