import { createContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { io } from 'socket.io-client'
import { Storage, STORAGE_KEYS } from '@utility/storage'
import { SocketEventEnum } from '@utility/constants'

export const SocketIo = createContext()

export const SocketContext = ({ children }) => {
  const { isUserLoggedIn, refreshToken, clinicData } = useSelector(state => state.auth)

  const socket = io(`${process.env.REACT_APP_SOCKET_URL}/${clinicData.clinicId}`, {
    transports: ['websocket'],
    reconnectionDelay: 2000,
    reconnectionDelayMax: 10000
  })

  useEffect(() => {
    socket.on(SocketEventEnum.Connect, () => {
      const currentToken = Storage.getItem(STORAGE_KEYS.token)
      socket.emit(SocketEventEnum.Authenticate, currentToken.accessToken)
    })
    socket.on(SocketEventEnum.Authorized, () => {
      console.log('>>> authorized')
    })
    socket.on(SocketEventEnum.Disconnect, reason => {
      console.log('disconnect: ', reason)
      if (reason === 'io server disconnect' && isUserLoggedIn) {
        // timeout 5s to reconnect
        setTimeout(() => {
          socket.connect()
        }, 5000)
      }
    })
    return () => {
      socket.close()
    }
  }, [socket])

  useEffect(() => {
    socket.connect()
  }, [refreshToken])

  return <SocketIo.Provider value={socket}>{children}</SocketIo.Provider>
}
