// REDUX ACTION TYPES
export const ACTION_TYPES = {
  // auth
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  UPDATE_USER: 'UPDATE_USER',
  CHECK_REMENBERED_USER_DONE: 'CHECK_REMENBERED_USER_DONE',
  // layout
  HANDLE_CONTENT_WIDTH: 'HANDLE_CONTENT_WIDTH',
  HANDLE_MENU_COLLAPSED: 'HANDLE_MENU_COLLAPSED',
  HANDLE_MENU_HIDDEN: 'HANDLE_MENU_HIDDEN',
  HANDLE_RTL: 'HANDLE_RTL',
  SAVE_CHECK_IN_SHEET: 'SAVE_CHECK_IN_SHEET',
  SAVE_PROPS_OF_PATIENT: 'SAVE_PROPS_OF_PATIENT'
}
