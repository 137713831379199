export const CountryEnum = {
  Vietnam: 192,
  Other: 0
}

export const CityEnum = {
  HaNoi: 24,
  TPHoChiMinh: 58,
  AnGiang: 1,
  BaRiaVungTau: 2,
  BacGiang: 3,
  BacKan: 4,
  BacLieu: 5,
  BacNinh: 6,
  BenTre: 7,
  BinhDinh: 8,
  BinhDuong: 9,
  BinhPhuoc: 10,
  BinhThuan: 11,
  CaMau: 12,
  CanTho: 13,
  CaoBang: 14,
  DaNang: 15,
  DakLak: 16,
  DakNong: 17,
  DienBien: 18,
  DongNai: 19,
  DongThap: 20,
  GiaLai: 21,
  HaGiang: 22,
  HaNam: 23,
  HaTinh: 25,
  HaiDuong: 26,
  HaiPhong: 27,
  HauGiang: 28,
  HoaBinh: 29,
  HungYen: 30,
  KhanhHoa: 31,
  KienGiang: 32,
  KonTum: 33,
  LaiChau: 34,
  LamDong: 35,
  LangSon: 36,
  LaoCai: 37,
  LongAn: 38,
  NamDinh: 39,
  NgheAn: 40,
  NinhBinh: 41,
  NinhThuan: 42,
  PhuTho: 43,
  PhuYen: 44,
  QuangBinh: 45,
  QuangNam: 46,
  QuangNgai: 47,
  QuangNinh: 48,
  QuangTri: 49,
  SocTrang: 50,
  SonLa: 51,
  TayNinh: 52,
  ThaiBinh: 53,
  ThaiNguyen: 54,
  ThanhHoa: 55,
  ThuaThienHue: 56,
  TienGiang: 57,
  TraVinh: 59,
  TuyenQuang: 60,
  VinhLong: 61,
  VinhPhuc: 62,
  YenBai: 63
}

export const RecurrenceOptionEnum = {
  NoRepeat: 0,
  Daily: 1,
  WeeklyOnDayOfWeek: 2,
  EveryWorkDaysOfWeek: 3,
  Custom: 4
}

export const RecurrenceFrequencyEnum = {
  DAILY: 3,
  WEEKLY: 2,
  MONTHLY: 1
}

export const RecurrenceEndsEnum = {
  Never: '0',
  OnDay: '1',
  After: '2'
}

export const EditDoctorScheduleCaseEnum = {
  SingleToSingle: 1,
  SingleToRecurring: 2,
  RecurringInfoOnly: 3,
  RecurringInfoAndRrule: 4,
  RecurringInfoAndToSingle: 5
}

export const EditDoctorScheduleApplyToEnum = {
  ThisOccurrence: 1,
  ThisAndFollowingOccurrences: 2
}

export const RecurrenceByWeekdayEnum = {
  MO: 0,
  TU: 1,
  WE: 2,
  TH: 3,
  FR: 4,
  SA: 5,
  SU: 6
}

export const AppointmentStatusEnum = {
  NotApproved: 1000,
  Approved: 2000,
  CheckedIn: 3000,
  InProgress: 4000,

  // DoneExamination: 4900,
  // TodoBilling: 6100,
  // InBilling: 6500,
  // DoneBilling: 6900,
  Finished: 9000,
  Canceled: 9999,
  Invalid: 10000
}

export const AppointmentStatusDisplayConfig = {
  [AppointmentStatusEnum.NotApproved]: { title: 'NotApproved', color: 'warning' },
  [AppointmentStatusEnum.Approved]: { title: 'Approved', color: 'secondary' },
  [AppointmentStatusEnum.CheckedIn]: { title: 'CheckedIn', color: 'primary' },
  [AppointmentStatusEnum.InProgress]: { title: 'InProgress', color: 'info' },

  // [AppointmentStatusEnum.DoneExamination]: { title: 'InProgress', color: 'success' },
  // [AppointmentStatusEnum.TodoBilling]: { title: 'InProgress', color: 'success' },
  // [AppointmentStatusEnum.InBilling]: { title: 'InProgress', color: 'success' },
  // [AppointmentStatusEnum.DoneBilling]: { title: 'InProgress', color: 'success' },
  [AppointmentStatusEnum.Finished]: { title: 'Finished', color: 'success' },
  [AppointmentStatusEnum.Canceled]: { title: 'Canceled', color: 'danger' },
  [AppointmentStatusEnum.Invalid]: { title: 'Invalid', color: 'warning' }
}

export const ActivityLogActionsEnum = {
  Create: 1,
  Update: 2,
  Enable: 3,
  Disable: 4,
  Deleted: 5,
  Pay: 6,
  Canceled: 7,
  Approved: 8,
  CheckIn: 9,
  Finished: 10,
  StartExam: 11,
  UpdateSingleToSingle: 12,
  UpdateSingleToRecurring: 13,
  UpdateRecurringThisOccurrence: 14,
  UpdateRecurringThisAndFollowingOccurrences: 15,
  UpdateRecurringInfoAndRule: 16,
  UpdateRecurringToSingle: 17,
  CreateRecurringSchedule: 18,
  CreateSingleSchedule: 19,
  DeleteSingleSchedule: 20,
  DeleteRecurringThisAndFollowingOccurrences: 21,
  DeleteRecurringThisOccurrences: 22,
  UpdatedServicesAndPackage: 23
}

export const DoctorScheduleActionEnum = [
  ActivityLogActionsEnum.UpdateSingleToSingle,
  ActivityLogActionsEnum.UpdateSingleToRecurring,
  ActivityLogActionsEnum.UpdateRecurringThisOccurrence,
  ActivityLogActionsEnum.UpdateRecurringThisAndFollowingOccurrences,
  ActivityLogActionsEnum.UpdateRecurringInfoAndRule,
  ActivityLogActionsEnum.UpdateRecurringToSingle,
  ActivityLogActionsEnum.CreateRecurringSchedule,
  ActivityLogActionsEnum.CreateSingleSchedule,
  ActivityLogActionsEnum.DeleteSingleSchedule,
  ActivityLogActionsEnum.DeleteRecurringThisAndFollowingOccurrences,
  ActivityLogActionsEnum.DeleteRecurringThisOccurrences
]
export const ActivityLogActionsDisplayConfig = {
  [ActivityLogActionsEnum.Create]: { title: 'Create' },
  [ActivityLogActionsEnum.Update]: { title: 'Update' },
  [ActivityLogActionsEnum.Enable]: { title: 'Enable' },
  [ActivityLogActionsEnum.Disable]: { title: 'Disable' },
  [ActivityLogActionsEnum.Deleted]: { title: 'Delete' },
  [ActivityLogActionsEnum.Canceled]: { title: 'Canceled' },
  [ActivityLogActionsEnum.Pay]: { title: 'Pay' },
  [ActivityLogActionsEnum.Approved]: { title: 'Approved' },
  [ActivityLogActionsEnum.CheckIn]: { title: 'CheckIn' },
  [ActivityLogActionsEnum.Finished]: { title: 'Finished' },
  [ActivityLogActionsEnum.StartExam]: { title: 'StartExam' },
  [ActivityLogActionsEnum.UpdateSingleToSingle]: { title: 'UpdateSingleToSingle' },
  [ActivityLogActionsEnum.UpdateSingleToRecurring]: { title: 'UpdateSingleToRecurring' },
  [ActivityLogActionsEnum.UpdateRecurringThisOccurrence]: {
    title: 'UpdateRecurringThisOccurrence'
  },
  [ActivityLogActionsEnum.UpdateRecurringThisAndFollowingOccurrences]: {
    title: 'UpdateRecurringThisAndFollowingOccurrences'
  },
  [ActivityLogActionsEnum.UpdateRecurringInfoAndRule]: { title: 'UpdateRecurringInfoAndRule' },
  [ActivityLogActionsEnum.UpdateRecurringToSingle]: { title: 'UpdateRecurringToSingle' },
  [ActivityLogActionsEnum.CreateRecurringSchedule]: { title: 'CreateRecurringSchedule' },
  [ActivityLogActionsEnum.CreateSingleSchedule]: { title: 'CreateSingleSchedule' },
  [ActivityLogActionsEnum.DeleteSingleSchedule]: { title: 'DeleteSingleSchedule' },
  [ActivityLogActionsEnum.DeleteRecurringThisAndFollowingOccurrences]: {
    title: 'DeleteRecurringThisAndFollowingOccurrences'
  },
  [ActivityLogActionsEnum.DeleteRecurringThisOccurrences]: { title: 'DeleteRecurringThisOccurrences' },
  [ActivityLogActionsEnum.UpdatedServicesAndPackage]: { title: 'UpdatedServicesAndPackage' }
}

export const ActivityLogsEntityTypeEnum = {
  Doctor: 1,
  ClinicPatient: 2,
  // ResultSheet : 3,
  CheckInSheet: 4,
  ServiceSheet: 5,
  Location: 6,
  Specialty: 7,
  Symptom: 8,
  User: 9,
  UserRole: 10,
  UserGroup: 11,
  Property: 12,
  Form: 13,
  Service: 14,
  Medicine: 15,
  Supplier: 16,
  PrescriptionTemplate: 17,
  ServiceTemplate: 18,
  StockSheet: 19,
  Invoice: 20,
  Appointment: 21,
  DoctorSchedule: 22,
  MedicineLot: 23,
  ClinicAppointment: 24,
  Schedule: 25,
  Prescription: 26
}

export const ActivityLogsEntityTypeDisplayConfig = {
  [ActivityLogsEntityTypeEnum.Doctor]: { title: 'Doctor' },
  [ActivityLogsEntityTypeEnum.CheckInSheet]: { title: 'CheckInSheet' },
  [ActivityLogsEntityTypeEnum.ServiceSheet]: { title: 'ServiceSheet' },
  [ActivityLogsEntityTypeEnum.Location]: { title: 'Location' },
  [ActivityLogsEntityTypeEnum.Specialty]: { title: 'Specialty' },
  [ActivityLogsEntityTypeEnum.Symptom]: { title: 'Symptom' },
  [ActivityLogsEntityTypeEnum.User]: { title: 'User' },
  [ActivityLogsEntityTypeEnum.UserRole]: { title: 'UserRole' },
  [ActivityLogsEntityTypeEnum.UserGroup]: { title: 'UserGroup' },
  [ActivityLogsEntityTypeEnum.Property]: { title: 'Property' },
  [ActivityLogsEntityTypeEnum.Form]: { title: 'Form' },
  [ActivityLogsEntityTypeEnum.Service]: { title: 'Service' },
  [ActivityLogsEntityTypeEnum.Medicine]: { title: 'Medicine' },
  [ActivityLogsEntityTypeEnum.Supplier]: { title: 'Supplier' },
  [ActivityLogsEntityTypeEnum.PrescriptionTemplate]: { title: 'PrescriptionTemplate' },
  [ActivityLogsEntityTypeEnum.Invoice]: { title: 'Invoice' },
  [ActivityLogsEntityTypeEnum.ServiceTemplate]: { title: 'ServiceTemplate' },
  [ActivityLogsEntityTypeEnum.MedicineLot]: { title: 'MedicineLot' },
  [ActivityLogsEntityTypeEnum.DoctorSchedule]: { title: 'DoctorSchedule' },

  [ActivityLogsEntityTypeEnum.ClinicAppointment]: { title: 'ClinicAppointment' },
  [ActivityLogsEntityTypeEnum.Schedule]: { title: 'Schedule' },
  [ActivityLogsEntityTypeEnum.ClinicPatient]: { title: 'ClinicPatient' },
  [ActivityLogsEntityTypeEnum.StockSheet]: { title: 'StockSheet' },
  [ActivityLogsEntityTypeEnum.Prescription]: { title: 'Prescription' }
}

export const ScheduleSlotAvailableDisplayConfig = {
  notApproved: { title: 'NotApproved', color: 'light-secondary' },
  available: { title: 'Available', color: 'success' },
  full: { title: 'Full', color: 'light-danger' }
}

export const GenderEnum = {
  Male: 0,
  Female: 1
}

export const PermissionTypeEnum = {
  None: 0,
  Read: 10,
  Write: 20
}

export const ClinicPlanBasicScreenEnum = {
  stockOnHand: 'stockOnHand',
  stockSheets: 'stockSheets',
  invoices: 'invoices',
  appointmentReports: 'appointmentReports',
  saleSummaryReports: 'saleSummaryReports',
  saleByServiceReports: 'saleByServiceReports',
  saleByMedicineLotsReports: 'saleByMedicineLotsReports',
  saleByDoctorReports: 'saleByDoctorReports',
  saleByClinicPatientReports: 'saleByClinicPatientReports',
  saleByServicePackageReports: 'saleByServicePackageReports',
  stockByMedicineLotReports: 'stockByMedicineLotReports',
  stockByMedicineReports: 'stockByMedicineReports',
  dashboard: 'dashboard',
  billing: 'billing'
}

export const TabEnum = {
  Settings: 'settings',
  Operations: 'operations',
  Lists: 'lists',
  Stock: 'stock',
  Reports: 'reports'
}

export const TitleTabEnum = {
  Settings: {
    id: 'title.clinicSettings',
    default: 'Clinic Settings'
  },
  Operations: {
    id: 'title.operations',
    default: 'Operations'
  },
  Lists: {
    id: 'title.lists',
    default: 'Lists'
  },
  Stock: {
    id: 'title.stock',
    default: 'Stock'
  },
  Reports: {
    id: 'title.reports',
    default: 'Report'
  }
}

export const FrontEndScreenEnum = {
  GeneralSettings: 1,
  Locations: 2,
  Specialties: 3,
  Doctors: 4,
  Symptoms: 5,
  Users: 6,
  UserRoles: 7,
  UserGroups: 8,
  DoctorSchedules: 9,
  Appointments: 10,
  ExaminationTask: 11,
  PatientRecords: 12,
  Properties: 13,
  Forms: 14,
  Medicines: 15,
  Services: 18,
  Packages: 19,
  BillingTask: 21,
  PurchasedPackages: 22,
  Invoices: 23,
  ServiceTemplates: 24,
  PrescriptionsTemplates: 25,
  StockOnHand: 26,
  StockSheets: 27,
  SettingSuppliers: 28,
  Dashboard: 29,
  AppointmentReports: 30,
  SaleSummaryReports: 31,
  StockByMedicineLotsReports: 36,
  SaleByServiceReports: 32,
  SaleByMedicineLotsReports: 33,
  SaleByDoctorReports: 37,
  SaleByClinicPatientReports: 34,
  StockByMedicineReports: 35,
  ActivityLogsReports: 38,
  SaleByServicePackageReports: 39,
  Laboratories: 40,
  LabResults: 41,
  ReExaminationAppointments: 42
}

export const PermissionScreens = [
  {
    frontendScreenTitle: 'title.generalSettings',
    frontendScreenId: FrontEndScreenEnum.GeneralSettings,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.locations',
    frontendScreenId: FrontEndScreenEnum.Locations,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.specialties',
    frontendScreenId: FrontEndScreenEnum.Specialties,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.doctors',
    frontendScreenId: FrontEndScreenEnum.Doctors,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.symptomSuggestion',
    frontendScreenId: FrontEndScreenEnum.Symptoms,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.users',
    frontendScreenId: FrontEndScreenEnum.Users,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.userRoles',
    frontendScreenId: FrontEndScreenEnum.UserRoles,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.userGroups',
    frontendScreenId: FrontEndScreenEnum.UserGroups,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.properties',
    frontendScreenId: FrontEndScreenEnum.Properties,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.forms',
    frontendScreenId: FrontEndScreenEnum.Forms,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.medicineLibrary',
    frontendScreenId: FrontEndScreenEnum.Medicines,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.services',
    frontendScreenId: FrontEndScreenEnum.Services,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.packages',
    frontendScreenId: FrontEndScreenEnum.Packages,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.prescriptionTemplates',
    frontendScreenId: FrontEndScreenEnum.PrescriptionsTemplates,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.serviceTemplates',
    frontendScreenId: FrontEndScreenEnum.ServiceTemplates,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.suppliers',
    frontendScreenId: FrontEndScreenEnum.SettingSuppliers,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.laboratories',
    frontendScreenId: FrontEndScreenEnum.Laboratories,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.doctorSchedules',
    frontendScreenId: FrontEndScreenEnum.DoctorSchedules,
    tab: TabEnum.Operations
  },
  {
    frontendScreenTitle: 'title.appointments',
    frontendScreenId: FrontEndScreenEnum.Appointments,
    tab: TabEnum.Operations
  },
  {
    frontendScreenTitle: 'title.todayExaminations',
    frontendScreenId: FrontEndScreenEnum.ExaminationTask,
    tab: TabEnum.Operations
  },
  {
    frontendScreenTitle: 'title.billing',
    frontendScreenId: FrontEndScreenEnum.BillingTask,
    tab: TabEnum.Operations
  },
  {
    frontendScreenTitle: 'title.activityLogs',
    frontendScreenId: FrontEndScreenEnum.ActivityLogsReports,
    tab: TabEnum.Operations
  },
  {
    frontendScreenTitle: 'title.stockOnHand',
    frontendScreenId: FrontEndScreenEnum.StockOnHand,
    tab: TabEnum.Stock
  },
  {
    frontendScreenTitle: 'title.stockSheets',
    frontendScreenId: FrontEndScreenEnum.StockSheets,
    tab: TabEnum.Stock
  },
  {
    frontendScreenTitle: 'title.patientRecords',
    frontendScreenId: FrontEndScreenEnum.PatientRecords,
    tab: TabEnum.Lists
  },
  // {
  //   frontendScreenTitle: 'title.purchasedPackages',
  //   frontendScreenId: FrontEndScreenEnum.PurchasedPackages,
  //   tab: TabEnum.Lists
  // },
  {
    frontendScreenTitle: 'title.invoices',
    frontendScreenId: FrontEndScreenEnum.Invoices,
    tab: TabEnum.Lists
  },
  {
    frontendScreenTitle: 'title.reExaminationAppointments',
    frontendScreenId: FrontEndScreenEnum.ReExaminationAppointments,
    tab: TabEnum.Lists
  },
  {
    frontendScreenTitle: 'title.labResults',
    frontendScreenId: FrontEndScreenEnum.LabResults,
    tab: TabEnum.Lists
  },
  {
    frontendScreenTitle: 'title.dashboard',
    frontendScreenId: FrontEndScreenEnum.Dashboard,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.saleSumaryReports',
    frontendScreenId: FrontEndScreenEnum.SaleSummaryReports,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.appointmentReport',
    frontendScreenId: FrontEndScreenEnum.AppointmentReports,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.stockMedicineLotsReports',
    frontendScreenId: FrontEndScreenEnum.StockByMedicineLotsReports,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.saleByServiceReports',
    frontendScreenId: FrontEndScreenEnum.SaleByServiceReports,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.saleByMedicineLotsReports',
    frontendScreenId: FrontEndScreenEnum.SaleByMedicineLotsReports,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.saleByDoctorReports',
    frontendScreenId: FrontEndScreenEnum.SaleByDoctorReports,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.saleByClinicPatientReports',
    frontendScreenId: FrontEndScreenEnum.SaleByClinicPatientReports,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.stockByMedicineReports',
    frontendScreenId: FrontEndScreenEnum.StockByMedicineReports,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.saleByServicePackageReports',
    frontendScreenId: FrontEndScreenEnum.SaleByServicePackageReports,
    tab: TabEnum.Reports
  }
]

export const UserStatusEnum = {
  Inactive: 500,
  Active: 1000
}

export const UserStatusDisplayConfig = {
  [UserStatusEnum.Inactive]: { title: 'Inactive', color: 'light-warning' },
  [UserStatusEnum.Active]: { title: 'Active', color: 'light-success' }
}

export const SkillsEnum = [
  { skillLabel: 'label.receptionist', skillId: 1 },
  { skillLabel: 'label.assistant', skillId: 2 },
  { skillLabel: 'label.doctor', skillId: 3 },
  { skillLabel: 'label.cashier', skillId: 4 }
]

export const RoleTypeEnum = {
  Owner: 10,
  Admin: 20,
  UserDefined: 30
}

export const FeatureEnum = {
  101: { action: 'add', subject: 'appointments' },
  102: { action: 'approve', subject: 'appointments' },
  103: { action: 'checkIn', subject: 'appointments' },
  104: { action: 'examination', subject: 'appointments' },
  105: { action: 'cancel', subject: 'appointments' },
  106: { action: 'billing', subject: 'appointments' }
}

export const ScreenEnum = {
  1: 'general',
  2: 'locations',
  3: 'specialties',
  4: 'doctors',
  5: 'symptoms',
  6: 'users',
  7: 'roles',
  8: 'groups',
  9: 'doctorSchedules',
  10: 'appointments',
  11: 'todayExaminations',
  12: 'patientRecords',
  13: 'properties',
  14: 'forms',
  15: 'medicineLibrary',
  18: 'services',
  19: 'packages',
  21: 'billing',
  22: 'purchasedPackages',
  23: 'invoices',
  24: 'serviceTemplates',
  25: 'prescriptionTemplates',
  26: 'stockOnHand',
  27: 'stockSheets',
  28: 'suppliers',
  29: 'dashboard',
  30: 'appointmentReports',
  31: 'saleSummaryReports',
  32: 'saleByServiceReports',
  33: 'saleByMedicineLotsReports',
  34: 'saleByClinicPatientReports',
  35: 'stockByMedicineReports',
  36: 'stockByMedicineLotReports',
  37: 'saleByDoctorReports',
  38: 'activityLogsReports',
  39: 'saleByServicePackageReports',
  40: 'laboratories',
  41: 'labResults',
  42: 'reExaminationAppointments'
}

export const KeyBoardEnum = {
  Escape: 'Escape',
  Tab: 'Tab',
  Enter: 'Enter'
}

export const FrontEndFeatureEnum = {
  AddAppointment: 101,
  ApproveAppointment: 102,
  CheckInAppointment: 103,
  Examination: 104,
  CancelAppointment: 105,
  Billing: 106
}

export const AppointmentConfig = {
  AllowBookingBeforeMinutes: 0
}

export const PhysicalFileTypeEnum = {
  Avatar: 1,
  PrintTemplate: 2,
  PropertyImage: 3
}

export const PhysicalFileStatusEnum = {
  New: 0,
  OK: 1,
  WillBeDeleted: 2,
  Deleted: 3,
  Corrupted: 4
}

export const EntityTypeEnum = {
  CheckInSheet: 4,
  ServiceSheet: 5,
  ClinicPatient: 2
}

export const PropertyValueTypeEnum = {
  FreeText: 1,
  ListOfOptions: 2,
  Date: 3,
  DateTime: 4,
  Image: 5
}

export const MedicineUnitTypeEnum = {
  Tablet: 1,
  Blister: 2,
  Packet: 3,
  Sachet: 4,
  Tube: 5,
  Bottle: 6,
  Set: 7
}

export const DefaultValueOptionsEnum = {
  None: 0,
  Single: 1,
  Associate: 2
}

export const SocketEventEnum = {
  Connect: 'connect',
  Authenticate: 'authenticate',
  Authorized: 'authorized',
  Disconnect: 'disconnect',
  AddAppointment: 'appointment.add',
  AddCheckInAppointment: 'appointment.add-check-in',
  ApproveAppointment: 'appointment.approve',
  CheckInAppointment: 'appointment.checkin',
  CancelAppointment: 'appointment.cancel',
  InvalidAppointment: 'appointment.invalid',
  StartExamAppointment: 'appointment.start-exam',
  FinishExamAppointment: 'appointment.finish-exam',
  CreateUpdatePrescription: 'appointment.create-update-prescription',
  CreateUpdateInvoice: 'invoice.create-update',
  CancelInvoice: 'invoice.cancel',
  PayInvoice: 'invoice.pay'
}

export const AppointmentsSocketEvents = [
  SocketEventEnum.AddAppointment,
  SocketEventEnum.AddCheckInAppointment,
  SocketEventEnum.ApproveAppointment,
  SocketEventEnum.CheckInAppointment,
  SocketEventEnum.StartExamAppointment,
  SocketEventEnum.CancelAppointment,
  SocketEventEnum.InvalidAppointment,
  SocketEventEnum.CreateUpdateInvoice,
  SocketEventEnum.CancelInvoice,
  SocketEventEnum.PayInvoice,
  SocketEventEnum.CreateUpdatePrescription
]

export const TodayExaminationsSocketEvents = [
  SocketEventEnum.AddCheckInAppointment,
  SocketEventEnum.CheckInAppointment,
  SocketEventEnum.CancelAppointment
]

export const BillingSocketEvents = [
  SocketEventEnum.CreateUpdateInvoice,
  SocketEventEnum.CancelInvoice,
  SocketEventEnum.PayInvoice
]

export const PackageTypeEnum = {
  ExaminationPack: 1,
  InjectionPack: 2,
  TestPack: 3
}

export const PackageStatusEnum = {
  Draft: 500,
  WaitForApprove: 1000,
  Approved: 2000,
  Disabled: 9000,
  Invalid: 10000
}

export const PackageStatusDisplayConfig = {
  [PackageStatusEnum.Draft]: { title: 'Draft', color: 'light-success' },
  [PackageStatusEnum.WaitForApprove]: { title: 'WaitForApprove', color: 'light-secondary' },
  [PackageStatusEnum.Approved]: { title: 'Approved', color: 'light-primary' },
  [PackageStatusEnum.Disabled]: { title: 'Disabled', color: 'secondary' },
  [PackageStatusEnum.Invalid]: { title: 'Invalid', color: 'warning' }
}

export const WorkflowTypeEnum = {
  OneDoctorWithPostBilling: 1
}

export const ReservedProperty = {
  [EntityTypeEnum.CheckInSheet]: ['symptom'],
  [EntityTypeEnum.ServiceSheet]: ['diagnosis', 'conclusion', 'next_appointment_date'],
  [EntityTypeEnum.ClinicPatient]: ['diagnosis', 'medical_history']
}
// check in late after 10 minutes
export const lateCheckInTime = 10

export const InvoicePaymentMethodEnum = {
  Cash: 1,
  BankTransfer: 2,
  EWallet: 3
}

export const InvoicePaymentMethodDisplayConfig = {
  [InvoicePaymentMethodEnum.Cash]: { title: 'Cash' },
  [InvoicePaymentMethodEnum.BankTransfer]: { title: 'BankTransfer' },
  [InvoicePaymentMethodEnum.EWallet]: { title: 'EWallet' }
}

export const InvoiceStatusEnum = {
  WaitForPayment: 1000,
  Paid: 9000,
  Canceled: 9999
}

export const InvoiceStatusDisplayConfig = {
  [InvoiceStatusEnum.WaitForPayment]: { title: 'WaitForPayment', color: 'primary' },
  [InvoiceStatusEnum.Paid]: { title: 'Paid', color: 'success' },
  [InvoiceStatusEnum.Canceled]: { title: 'Canceled', color: 'danger' }
}

export const StockSheetTypeEnum = {
  StockImportSheet: 1,
  StockExportSheet: 2
}

export const StockSheetTypeDisplayConfig = {
  [StockSheetTypeEnum.StockImportSheet]: { title: 'StockImportSheet', color: 'light-success' },
  [StockSheetTypeEnum.StockExportSheet]: { title: 'StockExportSheet', color: 'light-danger' }
}

export const ImportReasons = [
  { value: 'importNewGoods', label: 'importNewGoods' },
  { value: 'refund', label: 'refund' },
  { value: 'orthers', label: 'orthers' }
]

export const ExportReasons = [
  { value: 'sale', label: 'sale' },
  { value: 'damaged', label: 'damaged' },
  { value: 'lost', label: 'lost' },
  { value: 'outOfDate', label: 'outOfDate' },
  { value: 'orthers', label: 'orthers' }
]

export const DEFAULT_PAGE_SIZE = 50

export const DEFAULT_PAGE_NUMBER = 1

export const DueDateEnum = {
  InDate: 1,
  NearExpiration: 2,
  Expired: 3
}

export const MedicineStatusEnum = {
  InStock: 1,
  LowInStock: 2
}

export const MedicinesStatusDisplayConfig = {
  [MedicineStatusEnum.InStock]: { title: 'InStock' },
  [MedicineStatusEnum.LowInStock]: { title: 'LowInStock' }
}
export const DueDateDisplayConfig = {
  [DueDateEnum.InDate]: { title: 'InDate' },
  [DueDateEnum.NearExpiration]: { title: 'NearExpiration' },
  [DueDateEnum.Expired]: { title: 'Expired' }
}

export const ServiceTypeEnum = {
  Affiliate: true,
  NonAffiliate: false
}

export const ServiceTypeDisplayConfig = {
  [ServiceTypeEnum.Affiliate]: { title: 'affiliateService' },
  [ServiceTypeEnum.NonAffiliate]: { title: 'nonAffilitateService' }
}

export const QUERY_KEYS = {
  GET_MEDICINES: '/medicines',
  GET_SERVICES: '/services',
  GET_PACKAGES: '/packpages',
  GET_DAILY_STATICS: '/dashboard/today-statics',
  GET_MONTHLY_SALE_STATICS: '/dashboard/monthly-sales-stats',
  GET_MONTHLY_APPOINTMENT_STATICS: '/dashboard/monthly-appointment-stats',
  GET_TOP_SALE_SERVICES: '/dashboard/top-sale-services',
  GET_TOP_SALE_MEDICINES: '/dashboard/top-sale-medicines',
  GET_NEAR_EXPIRATION_MEDICINES: '/dashboard/near-expiration-medicines',
  GET_EXPIRED_MEDICINES: '/dashboard/expired-medicines',
  GET_LOW_IN_STOCK_MEDICINES: '/dashboard/low-in-stock-medicines'
}

export const ServicesOrderTypeEnum = {
  Packages: 1,
  Services: 2
}

export const ServicesOrderDisplayConfig = {
  [ServicesOrderTypeEnum.Packages]: { title: 'Packages' },
  [ServicesOrderTypeEnum.Services]: { title: 'Services' }
}

//Type selection in billing screen
export const typeSelect = [
  { label: 'Thuốc', value: 0 },
  { label: 'Gói dịch vụ', value: 1 }
]

export const ResponsiveTypeEnum = {
  Mobile: 0,
  Tablet: 1,
  LargeTablet: 2,
  Desktop: 3
}

export const ClinicPlanTypeEnum = {
  Trial: 1,
  Basic: 2,
  Full: 3,
  Advanced: 4,
  Vip: 5
}

export const ClinicPlanTypeConfig = {
  [ClinicPlanTypeEnum.Trial]: { title: 'TrialPackage' },
  [ClinicPlanTypeEnum.Basic]: { title: 'BasicPackage' },
  [ClinicPlanTypeEnum.Full]: { title: 'FullPackage' },
  [ClinicPlanTypeEnum.Advanced]: { title: 'AdvancedPackage' },
  [ClinicPlanTypeEnum.Vip]: { title: 'VipPackage' }
}

export const LaboratoryEnum = {
  HealthCare: 1,
  Greenlab: 2,
  Medilatec: 3
}

export const LaboratoryDisplayConfig = {
  [LaboratoryEnum.HealthCare]: { title: 'HealthCare' },
  [LaboratoryEnum.Greenlab]: { title: 'Greenlab' },
  [LaboratoryEnum.Medilatec]: { title: 'Medilatec' }
}

export const LaboratoryStatusEnum = {
  Developing: 0,
  Actived: 1,
  Inactive: 2
}

export const LaboratoryStatusDisplayConfig = {
  [LaboratoryStatusEnum.Developing]: { title: 'developing', color: 'light-secondary' },
  [LaboratoryStatusEnum.Actived]: { title: 'actived', color: 'light-success' },
  [LaboratoryStatusEnum.Inactive]: { title: 'inactive', color: 'light-warning' }
}

export const LabResultStatusEnum = {
  NotExisting: false,
  Existing: true
}

export const LabResultStatusDisplayConfig = {
  [LabResultStatusEnum.NotExisting]: { title: 'notExisting' },
  [LabResultStatusEnum.Existing]: { title: 'existing' }
}

export const ReExaminationAppointmentStatusEnum = {
  NotBooked: false,
  Booked: true
}

export const ReExaminationAppointmentStatusDisplayConfig = {
  [ReExaminationAppointmentStatusEnum.NotBooked]: { title: 'notBooked' },
  [ReExaminationAppointmentStatusEnum.Booked]: { title: 'booked' }
}
